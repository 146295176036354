/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

a.content-skip {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;  
}

:root {
  --body-background: #f2f2f2;
  --body-color: #444;
  --heading-color: black;
  --action-color: #d64045;
}

a.content-skip:focus {
  left: auto;
  top: auto;  
  color: #fff;
  background-color: var(--action-color);
  width: 20%;
  height: auto;
  overflow:auto;
  padding:.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 999;
  font-size: 1rem;
}

a.content-skip:active { 
  left: auto;
  top: auto;  
  color: #fff;
  background-color: var(--action-color);
  width: 20%;
  height: auto;
  overflow:auto;
  padding:.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 999;
  font-size: 1rem; 
}

body {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-size: 108%;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #ffb088;
}

h1 {
  margin: 1rem 0 3rem;
  text-align: left;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}


body > header img {
  display: inline-block;
  max-width: 100%;
}

body > nav a {
  text-decoration: none;
}

main {
  margin: 2rem auto 2rem;
  max-width: 100rem;
  min-height: calc(100vh - 200px);
  background: white;
  border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

.main-page {
  padding-left: 25px;
  padding-right: 25px;
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
}

hr {
  border: dashed;
  border-top: 2px dotted #333;
}